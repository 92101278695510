import { OverlayScrollbars, ClickScrollPlugin } from "overlayscrollbars";

export default class RuOverlayScrollbar {
  constructor(elem) {
    this.elem = document.querySelector(elem);

    this.init();
  }

  init() {
    OverlayScrollbars.plugin([ClickScrollPlugin]);
    console.log(`${this.elem} scrollbar`);
    const scrollBar = OverlayScrollbars(this.elem, {
      scrollbars: {
        theme: "os-theme-ru",
        visibility: "auto",
        autoHide: "never",
        autoHideDelay: 1300,
        autoHideSuspend: false,
        dragScroll: true,
        clickScroll: true,
        pointers: ["mouse", "touch", "pen"],
      },
    });
  }
}
/* export default class RuOverlayScrollbar {
  constructor(elem) {
    this.elem = document.querySelector(elem);

    this.init();
  }

  async init() {
    const { OverlayScrollbars, ClickScrollPlugin } = await import(
      "overlayscrollbars"
    );

    OverlayScrollbars.plugin([ClickScrollPlugin]);
    console.log(`${this.elem} scrollbar`);
    const scrollBar = OverlayScrollbars(this.elem, {
      scrollbars: {
        theme: "os-theme-ru",
        visibility: "auto",
        autoHide: "never",
        autoHideDelay: 1300,
        autoHideSuspend: false,
        dragScroll: true,
        clickScroll: true,
        pointers: ["mouse", "touch", "pen"],
      },
    });
  }
} */
