export default class PictureFn {
  constructor() {
    this.blurContainers = document.querySelectorAll(".blur_picturefn");
    this.init();
    console.log("blurContainers", this.blurContainers);
  }

  init() {
    this.blurContainers.forEach((blurContainer) => {
      console.log("inside");
      const blurImg = blurContainer.querySelector(".blur-load");
      const bluredImg = blurContainer.querySelector(".blured-load");

      function loaded() {
        blurImg.classList.add("loaded");
        bluredImg.classList.add("loaded");
        console.log("loaded");
      }

      if (blurImg.complete) {
        console.log("blurImg complete");
        loaded();
      } else {
        blurImg.addEventListener("load", loaded);
      }
    });
    console.log("Page loaded");
  }
}
