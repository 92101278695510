/////////////
// navigation functionallity
// Handles toggling the navigation menu for small screens (and enables TAB key - not yet)

const htmlElement = document.querySelector("html");
const menuToggle = document.querySelector(".menu_toggle");
const siteNavigation = document.querySelector(".menu-main-container");
const menuClose = document.querySelector(".menu_close");

///////////////
// open menu function - add classes
const addToggled = () => {
  menuToggle.classList.add("toggled");
  menuToggle.setAttribute("aria-expanded", "true");
  siteNavigation.classList.add("menu__toggled");
  menuClose.classList.add("toggled");
};
// close menu function - remove classes
const removeToggled = () => {
  menuToggle.classList.remove("toggled");
  menuToggle.setAttribute("aria-expanded", "false");
  siteNavigation.classList.remove("menu__toggled");
  menuClose.classList.remove("toggled");
};

///////////////
// click on menu_toggle - decide open or close
const menuToggled = () => {
  if (!menuToggle.classList.contains("toggled")) {
    addToggled();
    return;
  } else {
    removeToggled();
    return;
  }
};
// add menu toggle evenElistener
menuToggle.addEventListener("click", menuToggled);

///////////////
// Close Menu on click outside of header
menuClose.addEventListener("click", () => {
  if (menuToggle.classList.contains("toggled")) {
    //close menu
    removeToggled();
    return;
  }
});

///////////////
// Fire only by screen width and resize
window.addEventListener("resize", function () {
  if (window.matchMedia("(min-width: 900px)").matches) {
    removeToggled();
    //console.log("Screen width is at least 900px");
    return;
  }
});

//
//
//
///////////////
// set cat selection for vp btns on alliance page to inital value
const menuItemAlliance = document.querySelector(".menu-item-alliance"); // select menu element
if (menuItemAlliance) {
  menuItemAlliance.addEventListener("click", () => {
    sessionStorage.setItem("vpbtncat", 0); // set vp to 0 = vp_01
  });
}

//
//
//
//
////////////////////////////////////
//
// Lang switcher btn

// get elem
const langSwitcher = document.querySelector(".custom-lang-switcher");
const langSwitcherInput = langSwitcher.querySelector("input");
// add click listener
langSwitcherInput.addEventListener("click", () => {
  // console.log("click");
  changeLang(true);
});
//  get lang attribute
const lang = langSwitcher.querySelector("input").getAttribute("lang");
// set state depending on lang
console.log(lang);
if (lang == "en_US") {
  // console.log("is lang us");
  changeLang(false);
}

function changeLang(cursor) {
  // get url attribute
  const url = langSwitcher.querySelector("input").getAttribute("url");
  switch (cursor) {
    // click
    case true:
      langSwitcherInput.classList.toggle("toggle_state__checked");
      console.log("cursor");
      window.location.href = url;
      changLabelText();
      break;
    // no click
    case false:
      langSwitcher.checked = true;
      // toggle the btn state
      langSwitcher
        .querySelector("input")
        .classList.add("toggle_state__checked");
      // console.log("!cursor");
      break;
  }
}

function changLabelText() {
  const labelText = document.querySelector(".label_text");
  console.log("changelang");
  switch (labelText.innerHTML) {
    case "de":
      console.log("inner", labelText.innerHTML);
      labelText.innerHTML = "en";
      break;
    case "en":
      console.log("inner", labelText.innerHTML);
      labelText.innerHTML = "de";
      break;
  }
}
