//////////////////////////////
//
//
// lightbox Dialog Class
//
//
//
//

export default class ruLightbox {
  constructor(trigger, dialog) {
    this.trigger = document.querySelector(trigger);
    this.dialog = document.querySelector(dialog);
    this.closeBtn = this.dialog.querySelector(".closeBtn_lightbox");
    // run function to class call
    this.setListeners();
  }

  // add opening listeners
  setListeners() {
    this.trigger.addEventListener("keydown", (e) => {
      if (e.key === " " || e.key === "Enter") {
        this.openRuModal();
      }
    });
    this.trigger.addEventListener("click", () => {
      this.openRuModal();
    });
  }

  // open modal method -> what to to do when opening
  openRuModal() {
    // show modal
    this.dialog.showModal();
    // add listener for closing -> close btn
    this.closeBtn.addEventListener("click", () => this.clickCloseBtn());
    // add listener for closing -> dialog backdrop
    this.dialog.addEventListener("click", (e) => this.clickCloseOutside(e));
  }

  clickCloseBtn() {
    this.closeRuModal();
  }

  clickCloseOutside(e) {
    const dialogDimensions = this.dialog.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      this.closeRuModal();
    }
  }

  // close modal method
  closeRuModal() {
    this.dialog.close();
    // remove the close listeners
    this.closeBtn.removeEventListener("click", () => this.clickCloseBtn());
    this.closeBtn.removeEventListener("click", () => this.clickCloseOutside(e));
  }
}
