// run picture Class to load blurred image
import PictureFn from "./00_utils/picturefn.js";

// lightbox class code
import RuLightbox from "./components/ru_lightboxbasic.js";

// scrollbar
import RuOverlayScrollbar from "./components/overlayscrollbars.js";

const picturestest = new PictureFn();

/////////////////////////
// Calling ruScrollbar class
document.addEventListener("DOMContentLoaded", function () {
  const bodyScrollbar = new RuOverlayScrollbar("body");
});

// FOOTER newsletter lightbox
const newsletterDialog = new RuLightbox(
  "#newsletter_modal__btn",
  "#newsletter_modal"
);
